import {DateTime} from "luxon"

export const dateTime = (date: string): DateTime => {
  return DateTime.fromISO(date, {zone: "UTC"})
}

export const localDateTime = (date: string): DateTime => {
  return DateTime.fromISO(date)
}

export const now = () => {
  return DateTime.now().toISO()
}